

















































































































import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUser from '@/models/graphql/CommunityUser';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import FileResource from '@/models/graphql/FileResource';
import ButtonComponent from '@/components/ButtonComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import { deepGet } from '@/utils/ObjectHelpers';
import EditionExhibitorMapping from '@/models/graphql/EditionExhibitorMapping';
import Community from '@/models/graphql/Community';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Country from '@/models/graphql/Country';
import ActionRule from '@/utils/enums/ActionRule';
import ActionRuleService from '@/services/ActionRuleService';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';
import * as StringHelper from '@/utils/helpers/StringHelper';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');

@Component({
  components: {
    FontAwesomeComponent,
    PillWidget,
    ButtonIconComponent,
    AvatarSoloWidget,
    ButtonComponent,
  },
  inheritAttrs: false,
})
export default class ExhibitorCardWidget extends BaseCardWidget {
  @Prop({ required: true })
  readonly uid!: string;

  @Prop({ required: true, default: -1 })
  readonly id!: number;

  @Prop({ required: true })
  readonly name!: string;

  @Prop({ required: false, default: false })
  readonly featured!: boolean;

  @Prop({ required: false, default: null })
  readonly cardRoute!: string;

  @Prop({ required: false, default: null })
  readonly mapRoute!: string;

  @Prop({ required: false, default: null })
  readonly schemaCode!: string;

  @Prop({ required: false, default: {} })
  readonly bannerFileResource!: FileResource;

  @Prop({ required: false, default: {} })
  readonly logoFileResource!: FileResource;

  @Prop({ required: false, default: () => [] })
  readonly editionMappings!: EditionExhibitorMapping[];

  @Prop({ required: false, default: () => [] })
  readonly country!: Country;

  @Prop({ required: false, default: false })
  readonly displayHall!: boolean;

  @Prop({ required: false, default: null })
  readonly _isBookmarked!: string | null;

  @Prop({ required: false, default: null })
  readonly _isVisited!: string | null;

  @Prop({ default: false })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBorder!: boolean;

  @Prop({ default: 'DependencyWidgetStore' })
  readonly context!: string;

  @Prop({ required: false, default: null })
  readonly _actions!: { key: string; value: string }[];

  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @Getter
  authUser!: CommunityUser;

  @Getter
  community!: Community;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  // eslint-disable-next-line no-underscore-dangle
  private bookmarked = this._isBookmarked;

  private geozones: number[] = [];

  private halls: number[] = [];

  private FileResourceHelper = FileResourceHelper;

  private ActionRule = ActionRule;

  private ActionRuleService = ActionRuleService;

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private get mapNavigationUrl(): string {
    if (this.geozones && this.geozones.length > 0
      && this.halls && this.halls.length > 0
      && this.mapRoute && this.schemaCode) {
      return `${this.mapRoute.endsWith('/')
        ? this.mapRoute.trim()
          .slice(0, this.mapRoute.length - 1)
        // eslint-disable-next-line max-len
        : this.mapRoute.trim()}?edition=${this.schemaCode}&hall=${this.halls[0]}&${this.geozones.map((g, index) => `filters[geozones][${index}]=${g}`)
        .join('&')}`;
    }
    return '';
  }

  private get countryFlag(): string | null {
    if (this.country && 'iso' in this.country && this.country.iso
      && this.featureByKey(FeatureKeys.COMMUNITY_COMPANY_PROFILE_COUNTRY_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_COMPANY_PROFILE_COUNTRY_FEATURE).enabled) {
      return this.country.iso;
    }
    return null;
  }

  private get countryName(): string | null {
    if (this.country && 'name' in this.country && this.country.name) {
      return this.country.name;
    }
    return null;
  }

  private get bannerSrc(): string {
    if (this.bannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.bannerFileResource, 'w256')})`;
    }
    if (this.community.defaultExhibitorBannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.community.defaultExhibitorBannerFileResource, 'w256')})`;
    }
    return 'url(/img/banner/empty.svg)';
  }

  private get logoSrc(): string {
    if (this.logoFileResource) {
      return FileResourceHelper.getFullPath(this.logoFileResource, 'w96');
    }
    if (this.community.defaultExhibitorLogoFileResource) {
      return FileResourceHelper.getFullPath(this.community.defaultExhibitorLogoFileResource, 'w96');
    }
    return '';
  }

  private get boothNumbers(): string {
    if (this.editionMappings && this.editionMappings.length > 0) {
      const mapping = this.editionMappings
        .find((ed) => ed.editionExhibitor && ed.editionExhibitor.schemaCode === this.schemaCode);
      if (mapping
        && mapping.editionExhibitor
        && mapping.editionExhibitor.booths
        && mapping.editionExhibitor.booths.length > 0) {
        const { booths } = mapping.editionExhibitor;
        this.geozones = mapping.editionExhibitor.booths
          .filter((b) => b.geozone && b.geozone.id)
          .map((b) => b.geozone?.id) as number[];
        this.halls = mapping.editionExhibitor.booths
          .filter((b) => b.geozone && b.geozone.id)
          .map((b) => b.geozone?.exhibitHall?.id) as number[];
        if (booths.length > 0) {
          if (booths.length === 1) {
            return this.displayHall
            && booths[0].exhibitHall
            && booths[0].exhibitHall.name
              ? `${StringHelper.formatStringToWords(booths[0].exhibitHall.name)} ·
            ${this.$t('actions.booth')} ${booths[0].number}`
              : `${this.$t('actions.booth')} ${booths[0].number}`;
          }
          if (this.displayHall
            && booths[0].exhibitHall
            && booths[0].exhibitHall.name) {
            return `${StringHelper.formatStringToWords(booths[0].exhibitHall.name)} ·
            ${this.$t('actions.booth')} ${booths[0].number}
            ${this.$tc(
    'component.exhibitor-card.booth-more',
    0,
    { count: booths.length - 1 },
  )}`;
          }
          return `${this.$t('actions.booth')} ${mapping.editionExhibitor.booths[0].number}
            ${this.$tc(
    'component.exhibitor-card.booth-more',
    0,
    { count: booths.length - 1 },
  )}`;
        }
      }
    }
    return '';
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.bookmarked) {
      const temp = this.bookmarked;
      this.bookmarked = null;
      this.unBookmark({ uid: temp })
        .catch(() => {
          this.bookmarked = temp;
        });
    } else {
      this.bookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.EXHIBITOR,
      }).then((response) => {
        this.bookmarked = response?.uid || '';
        if (this.bookmarked) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.EXHIBITOR,
            StatLoggerActions.ADD,
            '',
            this.id,
            this.uid,
            StatLoggerCategories.BOOKMARK,
            this.$i18n.locale,
          );
        }
      }).catch(() => {
        this.bookmarked = null;
      });
    }
  }
}
